import React from 'react'

import { Link} from 'gatsby'

//Helpers
import {getLocation, getName, getFakeAddress, getStatus, is_Sold} from '../helpers/helper.developments'
import {getCover} from '../helpers/helper.rendering'
import { graphql, useStaticQuery } from 'gatsby';
import Loading from './Loading';

const developmentCard = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)
    const {development,skeleton} = props

    return (
        <div class={"card dev-card " + (skeleton ? 'skeleton' : '')}>
            {skeleton && <Loading /> }
            {is_Sold(development) && 
                <div className="development-end-tag">100% VENDIDO</div>
            }
            <div class="card-image">
                <img class="card-img" src={development && getCover(development.photos).image} alt={realEstate?.name} />
            </div>
            <div class="card-img-overlay d-flex align-items-end align-items-lg-start flex-lg-column text-white">
                <div class="content">
                    <div class="badge">Estado <span>----</span> {development && getStatus(development)}</div>
                    <h4 class="card-title mb-0 mb-lg-3 mt-lg-5">{development && getName(development)}</h4>   
                    <h4 class="d-inline-block card-title direccion mb-0 mb-lg-3 mt-lg-2">{development && getFakeAddress(development)}</h4>   
                    <h5 class="card-location">
                        {development && getLocation(development,true)[0]}
                        <br />
                        {development &&  getLocation(development,true)[1]}
                    </h5>
                </div>
                    <Link to={"/emprendimientos/" + development?.id} class="btn btn-outline mt-auto"><span class="d-none d-lg-inline">DESCUBRILO</span><span class="icon-plus d-lg-none"></span></Link>
            </div>
        </div> 
    )
}

export default developmentCard 